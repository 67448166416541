import React, {useState } from "react"
import { RichText } from "prismic-reactjs"
import { colors } from "../styles/styles"
import { IoIosInformationCircleOutline, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";

import '../styles/pricepage/priceTable.scss'
import { PriceTableDesc } from "./priceTableDesc";

export const PriceTableRow = ({row}) => {

    const check = <IoIosCheckmarkCircleOutline size={30} />
    const cross = <RxCrossCircled size={30} style={{color: "rgb(194, 194, 194)"}} />

    const [isHoverDescRows, setIsHoverDescRows] = useState(false)

    return (
        <>
            <div className="comparison-row__container">
                <div className="comparison-row__title">
                    <h4 className="is-size-6 is-size-7-touch" >{RichText.asText(row.primary.row_name)}</h4>
                    {row.primary.row_desc && (
                      <div className="comparison-row-title_info">
                        <IoIosInformationCircleOutline size={20} css={{':hover' : {color : colors.orange, width: "20px", height: "20px"}, 'transition' : "color 0.2s ease-in-out"}} onMouseEnter={() => setIsHoverDescRows(true)} onMouseLeave={() => setIsHoverDescRows(false)} />
                      </div>
                    )}
                    {isHoverDescRows && <PriceTableDesc isHover={isHoverDescRows} desc={row.primary.row_desc} />}
                </div>
                {row.fields.map((field, y) => (
                    <div key={y} className="comparison-row__content">
                    {field.row_value_bool
                        ? check
                        : field.row_value_text
                        ? field.row_value_text
                        : cross
                    }
                    </div>
                ))}
            </div>
        </>
    )

}
