import React,{ useState } from "react";

export const PriceTableDesc = ({desc, isHover, suppClass}) => {

    return (
        <div className={`price-table-desc__container ${suppClass}`} style={{opacity: isHover ? "1" : "0"}}>
            {desc && (desc.length > 0 ?
                <ul>
                    {desc.map((desc_row, i) => (
                        <li>{desc_row.text}</li>
                    ))}
                </ul>
                : <h3>{desc[0].text}</h3>)
            }
        </div>
    )
}