import PropTypes from "prop-types"
import React from "react"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import { RichText } from "prismic-reactjs"
import linkResolver from "../utils/linkResolver"
import Image from "./image"

const PricesPlanMobile = ({ pricepageMobile, priceYear, priceMonth }) =>
  pricepageMobile.plans.map((plan, planNum) => {
    return (
      <div className="plans__mobile" css={pricePlanMobile}>
        <div className="plans__mobile-type is-hidden-desktop">
          <div className="columns plan__mobile-header plan__img">
            <div className="box has-text-white has-text-centered">
              {pricepageMobile.plans[planNum].plan_image && (
                <figure className="image is-128x128 is-margin-0-auto">
                  <Image
                    sharp={pricepageMobile.plans[planNum].plan_imageSharp}
                    image={pricepageMobile.plans[planNum].plan_image}
                  />
                </figure>
              )}
              <h3 className="title is-4 has-text-white has-text-centered is-uppercase has-text-weight-semibold">
                {RichText.asText(pricepageMobile.plans[planNum].plan_name)}
              </h3>
              {pricepageMobile.plans[planNum].plan_description &&
                RichText.render(
                  pricepageMobile.plans[planNum].plan_description,
                  linkResolver
                )}
            </div>
          </div>

          {/* Annual pre-payment subscription */}
          <div className="columns plan__pricing mt-5">
            <div className="box">
              <div className="column has-text-centered">
                <h4 className="title is-5 has-text-weight-medium">
                  {RichText.asText(pricepageMobile.annual_subscription)}
                </h4>
              </div>
              <div className="column">
                <div className={`has-text-centered`}>
                  {pricepageMobile.annual_subscription_list[planNum]
                    .annual_subscription_list_price &&
                    RichText.render(
                      pricepageMobile.annual_subscription_list[planNum]
                        .annual_subscription_list_price,
                      linkResolver
                    )}
                  <span className={`tag has-text-weight-normal`}>
                    {pricepageMobile.annual_subscription_list[planNum]
                      .annual_subscription_list_price_aside &&
                      RichText.render(
                        pricepageMobile.annual_subscription_list[planNum]
                          .annual_subscription_list_price_aside,
                        linkResolver
                      )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Monthly subscription */}
          <div className="columns plan__pricing mt-5">
            <div className="box">
              <div className="column has-text-centered">
                <h4 className="title is-5 has-text-weight-medium">
                  {RichText.asText(pricepageMobile.monthly_subscription)}
                </h4>
              </div>
              <div className="column">
                <div className={`has-text-centered`}>
                  {pricepageMobile.monthly_subscription_list[planNum]
                    .monthly_subscription_list_price &&
                    RichText.render(
                      pricepageMobile.monthly_subscription_list[planNum]
                        .monthly_subscription_list_price,
                      linkResolver
                    )}
                  <span className={`tag has-text-weight-normal`}>
                    {pricepageMobile.monthly_subscription_list[planNum]
                      .monthly_subscription_list_price_aside &&
                      RichText.render(
                        pricepageMobile.monthly_subscription_list[planNum]
                          .monthly_subscription_list_price_aside,
                        linkResolver
                      )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Annual total */}
          {/* <div className="columns plan__pricing mt-5">
            <div className="box">
              <div className="column">
                <h4 className="title is-6 has-text-centered">
                  {RichText.asText(pricepageMobile.annual_total)}
                </h4>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  {priceYear[planNum]} €/HT
                </div>
              </div>
            </div>
          </div> */}

          {/* Monthly total */}
          {/* <div className="columns plan__pricing mt-5">
            <div className="box">
              <div className="column">
                <h4 className="title is-6 has-text-centered">
                  {RichText.asText(pricepageMobile.monthly_total)}
                </h4>
              </div>
              <div className="column">
                <div className="has-text-centered">
                  {priceMonth[planNum]} €/HT
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  })

PricesPlanMobile.propTypes = {}

export default PricesPlanMobile

const pricePlanMobile = css`
  .plans__mobile-type {
    margin-top: 125px;
    .plan__pricing .column:first-of-type {
      border-top: 0px;
    }
    .box {
      width: 100%;
    }
  }
`
