import React, { useState } from "react"
import { RichText } from "prismic-reactjs"

import { colors } from "../styles/styles"
import { IoIosInformationCircleOutline, IoIosArrowDown } from "react-icons/io";

import '../styles/pricepage/priceTable.scss'
import { PriceTableDesc } from "./priceTableDesc";
import { PriceTableRow } from "./priceTableRow";

function PriceTable({ name, name_desc, rows, rating }) {

  const [comparisonTableCSS, setComparisonTableCSS] = useState({display: "none", opacity: "0", transition: "all 0.3s ease"})
  const [arrowTitleCSS, setArrowTitleCSS] = useState({transform: "rotate(-90deg)", transition: "all 0.3s ease"})
  const [isHoverDescHeader, setIsHoverDescHeader] = useState(false)

  const handleDeployComparisonTable = () => {
    if (comparisonTableCSS.display === "none") {
      const copyComparisonTableCSS = {
        ...comparisonTableCSS,
        display: "flex",
        opacity: "1"
      };
      const copyArrowTitleCSS = {
        ...arrowTitleCSS,
        transform: "rotate(0deg)"
      }
      setComparisonTableCSS(copyComparisonTableCSS);
      setArrowTitleCSS(copyArrowTitleCSS)
    } else {
        const resetComparisonTableCSS = {
          ...comparisonTableCSS,
          display: "none",
          opacity: "0"
        };
        const copyArrowTitleCSS = {
          ...arrowTitleCSS,
          transform: "rotate(-90deg)"
        }
        setComparisonTableCSS(resetComparisonTableCSS);
        setArrowTitleCSS(copyArrowTitleCSS)
    }
  };

  return (
    <>
      <div className="price-table__container">
        <div className="price-table__header-container" onClick={() => handleDeployComparisonTable()}>
          <div className="price-table__header">
            <h3>{RichText.asText(name)}</h3>
            {name_desc && (
              <>
                <IoIosInformationCircleOutline size={20} css={{':hover' : {color : colors.orange}, 'transition' : "color 0.2s ease-in-out"}}  onMouseEnter={() => setIsHoverDescHeader(true)} onMouseLeave={() => setIsHoverDescHeader(false)} />
              </>
            )
          }
            <IoIosArrowDown size={20} css={arrowTitleCSS} />
            {isHoverDescHeader && <PriceTableDesc desc={name_desc} isHover={isHoverDescHeader} suppClass={"header"} /> }
          </div>
          {rating && (
            <>
              <div className="price-table__header-cover-rating-container">
                {rating.averageUnlimited.map((plan, i) => (
                  plan
                ))}
              </div>

              <div className="price-table__header-cover-rating-container">
                {rating.averageEnterprise.map((plan, i) => (
                    plan
                  ))}
              </div>

              <div className="price-table__header-cover-rating-container">
                {rating.averageProfessional.map((plan, i) => (
                    plan
                  ))}
              </div>

              <div className="price-table__header-cover-rating-container">
                {rating.averageEssential.map((plan, i) => (
                    plan
                  ))}
              </div>
            </>
          )}
        </div>
        <div className="comparison-table__container" css={comparisonTableCSS}>
          { rows &&
              rows.map((row, i) =>(
                <PriceTableRow key={i} row={row} />
              ))
          }
        </div>
      </div>
    </>
  )
}

export default PriceTable
